import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Hidden from "@material-ui/core/Hidden"
import styled from "styled-components"
import { Link } from "gatsby"

import Twitter from "../images/svg/twitter.svg"
import Facebook from "../images/svg/facebook.svg"
import Instgram from "../images/svg/instgram.svg"

import WrapTypo from "../components/WrapTypo"

const Footer = ({ isContact }) => {
  const data = useStaticQuery(graphql`
    query {
      FooterArt: file(relativePath: { eq: "footerArt.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      branding: file(relativePath: { eq: "branding.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coffeeSponsor: file(relativePath: { eq: "coffeeSponsor.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drinkSponsor: file(relativePath: { eq: "drinkSponsor.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      officePartner: file(relativePath: { eq: "officePartner.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      materialSponsor: file(relativePath: { eq: "turner.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      totalProduce: file(relativePath: { eq: "totalProduce.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const SponserWidth = styled.div`
    @media screen and (max-width: 600px) {
      width: 87%;
    }
    @media screen and (min-width: 600px) and (max-width: 775px) {
      width: 47%;
    }

    @media screen and (min-width: 775px) and (max-width: 1280px) {
      width: 35%;
    }
  `

  const IconWidth = styled.div`
    @media screen and (max-width: 600px) {
      width: 54%;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 24%;
      max-width: 400px;
    }
  `

  const CompanyWidth = styled.div`
    @media screen and (max-width: 600px) {
      width: 86%;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      width: 58%;
      max-width: 400px;
    }
  `

  return (
    <footer style={{ backgroundColor: "#1A1A1A", color: "#E8DED3" }}>
      <Hidden mdDown>
        <div style={{ padding: "96px 0 64px" }}>
          <div
            style={{
              width: "20%",
              margin: "auto",

              position: "relative",
              top: "27.71%",
            }}
          >
            <a href="/#top">
              <Img fluid={data.FooterArt.childImageSharp.fluid} />
            </a>
          </div>
        </div>
        {!isContact && (
          <div style={{ padding: "0 0 96.49px", fontSize: "10px" }}>
            <div
              style={{
                width: "85%",
                margin: "auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "15%" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Coffee Sponsor</p>
                </div>
                <div>
                  <a
                    href="http://brooklynroasting.jp/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.coffeeSponsor.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
              <div style={{ width: "15%" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Drink Sponsor</p>
                </div>
                <div>
                  <a
                    href="http://www.brooklynbrewery.jp/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.drinkSponsor.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
              <div style={{ width: "15%" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Office Partner</p>
                </div>
                <div>
                  <a
                    href="https://week-office.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.officePartner.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
              <div style={{ width: "15%" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Produce Partner</p>
                </div>
                <div>
                  <a
                    href="https://ancr.tokyo/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.totalProduce.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
              <div style={{ width: "15%" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Creative Partner</p>
                </div>
                <div>
                  <a
                    href="https://honey-at.co/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.branding.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
              <div style={{ width: "15%" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Material Sponsor</p>
                </div>
                <div>
                  <a
                    href="https://www.turner.co.jp/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.materialSponsor.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{ padding: "0 0 40px" }}>
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "85%",
              margin: "auto",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "25%",
                fontSize: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",

                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "24px",
                  }}
                >
                  <Link to="https://www.sunfrt.co.jp/company/profile_comp.html">
                    運営会社
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "24px",
                  }}
                >
                  <Link to="https://www.sunfrt.co.jp/policy/">
                    個人情報保護方針
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "24px",
                  }}
                >
                  <Link to="/contacts">お問い合わせ</Link>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "16%",
                fontSize: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CopyLight />
                </div>
              </div>
            </div>
            <div style={{ width: "25%" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    rel="noreferrer"
                    target="_blank"
                    to="https://twitter.com/A_YOTSUYA"
                  >
                    <Twitter
                      style={{
                        width: "21.07px",
                        height: "17.15px",
                        marginRight: "21.14px",
                      }}
                    />
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    rel="noreferrer"
                    target="_blank"
                    to="https://www.facebook.com/a.yotsuya/"
                  >
                    <Facebook
                      style={{
                        width: "9.94px",
                        height: "18.48px",
                        marginRight: "23.94px",
                      }}
                    />
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    rel="noreferrer"
                    target="_blank"
                    to="https://www.instagram.com/a_yotsuya/"
                  >
                    <Instgram
                      style={{
                        width: "18.2px",
                        height: "18.13px",
                      }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden lgUp>
        <div
          style={{
            textAlign: "center",
            fontSize: "10px",
            padding: "64px 0 24px",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                width: "183px",
                height: "101.05px",
                margin: "auto",
                marginBottom: "24px",
              }}
            >
              <a href="/#top">
                <Img fluid={data.FooterArt.childImageSharp.fluid} />
              </a>
            </div>
            <SponserWidth
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                margin: "auto",
                marginBottom: "40px",
              }}
            >
              <div style={{ width: "48%", marginBottom: "24px" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Coffee Sponsor</p>
                </div>
                <div>
                  <a
                    href="http://brooklynroasting.jp/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.coffeeSponsor.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
              <div style={{ width: "48%", marginBottom: "24px" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Drink Sponsor</p>
                </div>
                <div>
                  <a
                    href="http://www.brooklynbrewery.jp/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.drinkSponsor.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
              <div style={{ width: "48%", marginBottom: "24px" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Office Partner</p>
                </div>
                <div>
                  <a
                    href="https://week-office.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.officePartner.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
              <div style={{ width: "48%", marginBottom: "24px" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Produce Partner</p>
                </div>
                <div>
                  <a
                    href="https://ancr.tokyo/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.totalProduce.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
              <div style={{ width: "48%" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Creative Partner</p>
                </div>
                <div>
                  <a
                    href="https://honey-at.co/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.branding.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
              <div style={{ width: "48%" }}>
                <div style={{ textAlign: "center", marginBottom: "5px" }}>
                  <p>Material Sponsor</p>
                </div>
                <div>
                  <a
                    href="https://www.turner.co.jp/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Img fluid={data.materialSponsor.childImageSharp.fluid} />
                  </a>
                </div>
              </div>
            </SponserWidth>
            <CompanyWidth
              style={{
                margin: "auto",
                marginBottom: "24px",
              }}
            >
              <div style={{ marginBottom: "24px" }}>
                <Link
                  rel="noreferrer"
                  target="_blank"
                  to="https://www.sunfrt.co.jp/company/profile_comp.html"
                >
                  <p>運営会社</p>
                </Link>
              </div>
              <div style={{ marginBottom: "24px" }}>
                <Link
                  rel="noreferrer"
                  target="_blank"
                  to="https://www.sunfrt.co.jp/policy/"
                >
                  <p>個人情報保護方針</p>
                </Link>
              </div>
              <div>
                <Link to="/contacts">
                  <p>お問い合わせ</p>
                </Link>
              </div>
            </CompanyWidth>
            <div style={{ marginBottom: "24px" }}>
              <IconWidth
                style={{
                  margin: "auto",
                  marginBottom: "24px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Link
                    rel="noreferrer"
                    target="_blank"
                    to="https://twitter.com/A_YOTSUYA"
                  >
                    <Twitter />
                  </Link>
                </div>
                <div>
                  <Link
                    rel="noreferrer"
                    target="_blank"
                    to="https://www.facebook.com/a.yotsuya/"
                  >
                    <Facebook />
                  </Link>
                </div>
                <div>
                  <Link
                    rel="noreferrer"
                    target="_blank"
                    to="https://www.instagram.com/a_yotsuya/"
                  >
                    <Instgram />
                  </Link>
                </div>
              </IconWidth>
            </div>
            <div>
              <CopyLight />
            </div>
          </div>
        </div>
      </Hidden>
    </footer>
  )
}

const CopyLight = () => {
  return (
    <>
      <WrapTypo
        color="#E8DED3"
        mt="0"
        style={{ opacity: "0.6", fontSize: "10px" }}
      >
        © Sun Frontier Fudousan Co., Ltd.
      </WrapTypo>
    </>
  )
}

export default Footer
